import "./About01.css";

function About01() {
  return (
    <div id="about" className="About01 section">
      <div className="container">
        <div className="About01-box">
          <div>
            <h2>Get Ready to Go Nuts for XRP!</h2>
            <p>
              Introducing $XRPNUT, the memecoin that's taking the crypto world
              by storm. Join the nutty community and let's crack open some
              serious gains together.
            </p>

            <a
              href="https://t.me/xrpnutonsol"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn01">JOIN OUR COMMNUNITY</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About01;
