import "./Token01.css";

import pic01 from "../img/cont/mascot-02-rev.webp";

function Token01() {
  return (
    <div id="token" className="Token01 section">
      <div className="container">
        <div className="Token01-box">
          <img src={pic01} alt="picture"></img>

          <div>
            <div className="ca">
              <h3>CA:</h3>
              <p>EqNBsZ6rckymzTNzmx3r1DppQhChtEW5LYKT8TpWpump</p>
            </div>
            <div>
              <h3>NAME:</h3>
              <p>EXTREMELY RETARDED PNUT</p>
            </div>
            <div>
              <h3>Symbol:</h3>
              <p>$XRPNUT</p>
            </div>
            <div>
              <h3>Network:</h3>
              <p>Solana</p>
            </div>
            <div>
              <h3>Supply:</h3>
              <p>1 Billion</p>
            </div>

            <div className="btnFlex">
              <a
                href="https://raydium.io/swap/?inputMint=sol&outputMint=EqNBsZ6rckymzTNzmx3r1DppQhChtEW5LYKT8TpWpump"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn03">BUY $XRPNUT</button>
              </a>
              <a
                href="https://www.dextools.io/app/en/token/xrpnut?t=1733955070136"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn01">VIEW CHART</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Token01;
