import "./Navbar01.css";
import icon01 from "../img/icons/menu.svg";
import React, { useState, useEffect } from "react";

let menu = document.getElementsByClassName(".menu-icon");

function Navbar01() {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  useEffect(() => {
    const openNavbar = (event) => {
      if (event.target.tagName !== "BUTTON") {
        setIsNavbarOpen(false);
      }
    };

    document.addEventListener("click", openNavbar);

    return () => {
      document.removeEventListener("click", openNavbar);
    };
  }, []);
  return (
    <header className="Navbar01 section">
      <div className="container">
        <div className="Navbar01-box">
          <div className="Navbar01-logo">
            <h2>$XRPNUT</h2>
          </div>

          <ul className="Navbar01-links">
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#token">Token</a>
            </li>
            <li>
              <a href="#join">Community</a>
            </li>
            <li>
              <a
                href="https://raydium.io/swap/?inputMint=sol&outputMint=EqNBsZ6rckymzTNzmx3r1DppQhChtEW5LYKT8TpWpump"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn03">RAYDIUM</button>
              </a>
            </li>
          </ul>

          <button className="menu-icon" onClick={() => setIsNavbarOpen(true)}>
            X
          </button>
        </div>
      </div>

      <div
        className="Navbar01Mobile section"
        style={{
          transform: isNavbarOpen ? "translateY(0)" : "translateY(-17rem)",
          transition: "300ms",
        }}
      >
        <div className="container">
          <ul className="Navbar01Mobile-links">
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#token">Token</a>
            </li>
            <li>
              <a href="#join">Community</a>
            </li>
            <li>
              <a
                href="https://raydium.io/swap/?inputMint=sol&outputMint=EqNBsZ6rckymzTNzmx3r1DppQhChtEW5LYKT8TpWpump"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn03">RAYDIUM</button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Navbar01;
