import "./Stats01.css";

function Stats01() {
  return (
    <div id="token" className="Stats01 section">
      <div className="container">
        <div className="Stats01-box">
          <div>
            <h3>Renounced</h3>
            <p>
              To improve security and stability of $XRPNUT, the ownership of the
              contract has been renounced.
            </p>
          </div>

          <div>
            <h3>Burnt LP</h3>
            <p>
              To prevent potential rug pulls and market manipulation, the
              liquidity pool tokens have been burned.
            </p>
          </div>

          <div>
            <h3>Zero Taxes</h3>
            <p>
              No taxes on buying or selling. Enjoy seamless transactions and
              keep more of your hard-earned crypto.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stats01;
