import "./Hero01.css";
import pic01 from "../img/cont/mascot-01-rev.webp";

import icon01 from "../img/icons/dextools.png";
import icon02 from "../img/icons/solscan.png";
import icon03 from "../img/icons/raydium.png";
import icon04 from "../img/icons/twitter.svg";
import icon05 from "../img/icons/telegram.svg";

function Hero01() {
  return (
    <div className="Hero01 section">
      <div className="container">
        <div className="Hero01-box">
          <div>
            <h1>
              The <span>Nuttiest </span> Community in Crypto
            </h1>
            <p>
              $XRPNUT is a community-driven project that aims to bring fun,
              excitement, and potentially life-changing rewards to its holders.
            </p>

            <h3 className="ca">
              CA: <span>EqNBsZ6rckymzTNzmx3r1DppQhChtEW5LYKT8TpWpump</span>
            </h3>

            <ul className="Hero01-links">
              <li>
                <a
                  href="https://www.dextools.io/app/en/token/xrpnut?t=1733955070136"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={icon01} alt="dextools"></img>
                </a>
              </li>
              <li>
                <a
                  href="https://solscan.io/token/EqNBsZ6rckymzTNzmx3r1DppQhChtEW5LYKT8TpWpump"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={icon02} alt="solscan"></img>
                </a>
              </li>
              <li>
                <a
                  href="https://raydium.io/swap/?inputMint=sol&outputMint=EqNBsZ6rckymzTNzmx3r1DppQhChtEW5LYKT8TpWpump"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={icon03} alt="raydium"></img>
                </a>
              </li>
              <li>
                <a
                  href="https://x.com/xrpnutonsol?s=21&t=fZvGJb8yROxFGmLgRXwb1g"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={icon04} alt="twitter"></img>
                </a>
              </li>
              <li>
                <a
                  href="https://t.me/xrpnutonsol"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={icon05} alt="telegram"></img>
                </a>
              </li>
            </ul>
          </div>

          <img src={pic01} alt="picture"></img>
        </div>
      </div>
    </div>
  );
}

export default Hero01;
