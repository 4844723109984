import "./Faq01.css";

function Faq01() {
  return (
    <div id="join" className="Faq01 section">
      <div className="container">
        <div className="Faq01-box">
          <h2>Frequently Asked</h2>
          <div>
            <h3 className="question"> What is $XRPNUT?</h3>
            <p className="answer active">
              $XRPNUT is a community-driven memecoin inspired by the popular
              meme of Peanut the Squirrel.
            </p>
          </div>
          <div>
            <h3 className="question"> How can I get involved with $XRPNUT?</h3>
            <p className="answer ">
              You can get involved by participating in community discussions and
              spreading the word on Telegram and Twitter.
            </p>
          </div>
          <div>
            <h3 className="question">
              What are the benefits of holding $XRPNUT?
            </h3>
            <p className="answer ">
              As a holder of $XRPNUT, you'll be part of a growing community,
              have the opportunity to participate in future projects, and
              potentially benefit from significant price appreciation.
            </p>
          </div>
          <div>
            <h3 className="question"> How can I buy $XRPNUT?</h3>
            <p className="answer ">
              You can buy $XRPNUT on decentralized exchanges (DEX) like Raydium.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq01;
