import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Navbar01 from "./components/Navbar01";

import Hero01 from "./components/Hero01";
import About01 from "./components/About01";
import Stats01 from "./components/Stats01";
import Token01 from "./components/Token01";

import Faq01 from "./components/Faq01";
import Footer01 from "./components/Footer01";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <div className="bgBlur"></div>

    <Navbar01 />

    <Hero01 />

    <About01 />
    <Stats01 />

    <Token01 />

    <Faq01 />

    <Footer01 />
  </React.StrictMode>
);
