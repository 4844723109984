import "./Footer01.css";
import icon01 from "../img/icons/dextools.png";
import icon02 from "../img/icons/solscan.png";
import icon03 from "../img/icons/raydium.png";
import icon04 from "../img/icons/twitter.svg";
import icon05 from "../img/icons/telegram.svg";

function Footer01() {
  return (
    <header className="Footer01">
      <div className="Footer01-logo">
        <h2>$XRPNUT @2024</h2>
      </div>

      <ul className="Footer01-links">
        <li>
          <a
            href="https://www.dextools.io/app/en/token/xrpnut?t=1733955070136"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={icon01} alt="dextools"></img>
          </a>
        </li>
        <li>
          <a
            href="https://solscan.io/token/EqNBsZ6rckymzTNzmx3r1DppQhChtEW5LYKT8TpWpump"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={icon02} alt="solscan"></img>
          </a>
        </li>
        <li>
          <a
            href="https://raydium.io/swap/?inputMint=sol&outputMint=EqNBsZ6rckymzTNzmx3r1DppQhChtEW5LYKT8TpWpump"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={icon03} alt="raydium"></img>
          </a>
        </li>
        <li>
          <a
            href="https://x.com/xrpnutonsol?s=21&t=fZvGJb8yROxFGmLgRXwb1g"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={icon04} alt="twitter"></img>
          </a>
        </li>
        <li>
          <a
            href="https://t.me/xrpnutonsol"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={icon05} alt="telegram"></img>
          </a>
        </li>
      </ul>
    </header>
  );
}

export default Footer01;
